var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "tit": "Projects",
      "bg": "/images/sub/visual/sv--business.jpg"
    }
  }), _c('section', {
    staticClass: "section"
  }, [_c('v-container', [_c('table', {
    staticClass: "board-view"
  }, [_c('tr', [_c('th', {
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v(_vm._s(_vm.product.subject))])]), _c('tr', [_c('td', {
    staticClass: "content_box",
    attrs: {
      "colspan": "2"
    }
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.product.content)
    }
  })])])]), _c('table', {
    staticClass: "board-view-navi mt-20 mt-lg-40"
  }, [_c('tr', [_c('th', [_vm._v("Prev")]), _c('td', [_c('a', {
    staticClass: "d-block ellip",
    attrs: {
      "href": _vm.prevBoard._id
    }
  }, [_vm._v(_vm._s(_vm.prevBoard.subject))])])]), _c('tr', [_c('th', [_vm._v("Next")]), _c('td', [_c('a', {
    staticClass: "d-block ellip",
    attrs: {
      "href": _vm.nextBoard._id
    }
  }, [_vm._v(_vm._s(_vm.nextBoard.subject))])])])]), _c('div', {
    staticClass: "d-flex justify-end mt-50 mt-lg-100"
  }, [_c('v-btn', {
    attrs: {
      "outlined": "",
      "color": "grey-7"
    },
    on: {
      "click": _vm.link
    }
  }, [_vm._v("LIST")])], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }